import BlockContent from "@sanity/block-content-to-react";
import { motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import dynamic from "next/dynamic";
import Head from "next/head";
import Image from "next/image";
import { CheckCircle, CreditCard, StarFour } from "phosphor-react";
import React from "react";
import { urlFor } from "../lib/generic";
import { serializers } from "../plugins/sanity";
// Images
import scribbles from "../public/assets/brand/scribbles.svg";
import semTeamwork from "../public/assets/brand/sem-logo-teamwork.png";
import semPreview from "../public/assets/brand/sem-preview.png";

// Components
const Button = dynamic(() => import("../components/interface/button"));
const Disclosure = dynamic(() => import("../components/interface/disclosure"));
const General = dynamic(() => import("../components/layouts/general"));

export async function getStaticProps({ locale }) {
  const sanity = (await import("../plugins/sanity")).default;
  const groq = (await import("groq")).default;
  const request = groq`{
    // Find evey element that is of type page, with provided slug, of current language and not a draft
    "page": *[_type == "product.page" && metadata.slug.current == "home" && __i18n_lang == "${locale}" && !(_id in path('drafts.**'))][0]{
      _id,
      slug,
      name,
      content,
      metadata
    },
    // Find evey element that is of type package, of current language, not a draft and ordered by the sortOrder field
    "modules": *[_type == "product.modules" && __i18n_lang == "${locale}" && !(_id in path('drafts.**'))][0] {
      _id,
      list
    }
  }`;
  const query = await sanity.fetch(request);

  return {
    props: {
      page: query.page,
      modules: query.modules,
      ...(await serverSideTranslations(locale, ["common", "home"])),
    },
  };
}

function Home({ page, modules }) {
  const { t } = useTranslation(["common", "home"]);

  return (
    <>
      <Head>
        <title>{page.metadata.title}</title>
        <meta name="description" content={page.metadata.description} />
      </Head>

      <motion.div
        className="w-full overflow-x-hidden last-of-type:pb-2"
        transition={{ duration: 0 }}
        layout
      >
        {/* Landing */}
        <motion.section
          className={`w-full min-h-screen flex px-6 `}
          transition={{ duration: 0 }}
          layout
        >
          <div className={`container mx-auto flex-1 flex`}>
            {/* Background Scribbles */}
            <motion.div
              className={`absolute inset-0 flex`}
              transition={{ duration: 0 }}
              layout
            >
              <div className={`select-none relative flex-1 filter opacity-10`}>
                <Image
                  alt={`Background pattern`}
                  src={scribbles}
                  layout={`fill`}
                  objectFit={`cover`}
                  objectPosition={"center"}
                  priority
                />
              </div>
            </motion.div>

            {/* Content */}
            <div
              className={`flex flex-col flex-auto justify-center items-center space-y-12 md:flex-row md:space-y-24 md:space-x-4 xl:space-x-10`}
            >
              <div
                className={`flex flex-col space-y-5 justify-start items-stretch w-full md:mt-12 md:space-y-6 lg:w-4/12 lg:space-y-12 lg:justify-between `}
              >
                {/* Landing title */}
                <h1
                  className={`w-full text-4xl items-stretch text-brand-500 font-bold max-w-xs md:max-w-auto lg:text-5xl`}
                >
                  {
                    page.content.filter(
                      (element) => element.slug.current === "landing-title"
                    )[0].value
                  }
                </h1>

                {/* Buttons */}
                <div
                  className={`flex-auto flex flex-col space-y-3 lg:max-w-md xl:max-w-sm`}
                >
                  <Button
                    tabIndex={-1}
                    aria-label={t("buttons.features")}
                    to={"features"}
                    appearence={`wide`}
                    icon={<StarFour weight={`fill`} />}
                    label={t("buttons.features")}
                  />
                  <Button
                    aria-label={t("buttons.plans")}
                    href={"/plans"}
                    appearence={`wide`}
                    icon={<CreditCard weight={`fill`} />}
                    label={t("buttons.plans")}
                  />
                </div>
              </div>

              {/* Landing Image */}
              <div
                className={`h-1/3 w-full select-none flex min-w-[50%] md:h-full lg:w-8/12`}
              >
                <div className={`relative flex-1 lg:hidden`}>
                  <Image
                    alt={`SEM`}
                    src={semPreview}
                    layout={`fill`}
                    objectFit={`contain`}
                  />
                </div>
                <div className={`relative flex-1 hidden lg:block`}>
                  <Image
                    alt={`SEM`}
                    src={semTeamwork}
                    layout={`fill`}
                    objectFit={`contain`}
                    objectPosition={`center right`}
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.section>

        {/* Title */}
        <section className={`w-full px-6`}>
          <div
            className={`container mx-auto h-full flex mt-12 mb-6 lg:mb-16 xl:mt-16`}
          >
            <h1
              className={`text-3xl flex-auto text-brand-500 font-bold lg:text-4xl max-w-md`}
            >
              {
                page.content.filter(
                  (element) => element.slug.current === "sections-title"
                )[0].value
              }
            </h1>
          </div>
        </section>

        {/* Sections */}
        <motion.section className={`w-full px-6`}>
          {page.content
            .filter((element) => element._type === "section")
            .map((section: any, index: number) => (
              <motion.div
                className={`mb-12 container mx-auto flex flex-col px-4 rounded overflow-hidden even:md:flex-row-reverse odd:md:flex-row md:justify-between md:items-center `}
                key={index}
                layout
              >
                {/* Image */}
                <span
                  className={`w-full h-52 flex items-center justify-center p-4 md:w-2/5 lg:w-5/12 lg:h-60 2xl:w-7/12 2xl:h-72`}
                >
                  <span
                    className={`w-full h-full relative flex items-center justify-center`}
                  >
                    <Image
                      alt={section.title}
                      src={urlFor(section.image).url()}
                      layout={`fill`}
                      objectFit={`contain`}
                    />
                  </span>
                </span>
                {/* Text */}
                <span
                  className={`flex-auto flex flex-col prose-xl py-6 md:w-3/5 lg:w-7/12 2xl:prose-2xl`}
                >
                  <h2>{section.title}</h2>
                  <BlockContent
                    blocks={section.content}
                    serializers={serializers}
                  />
                </span>
              </motion.div>
            ))}
        </motion.section>

        {/* Features */}

        <section id={`features`} className={`w-full flex flex-col px-6`}>
          <motion.div
            className={`container mx-auto`}
            transition={{ duration: 0 }}
            layout
          >
            <motion.div className={`flex mt-12 mb-6`} layout>
              <h1
                className={`text-3xl flex-auto text-brand-500 font-bold lg:text-4xl`}
              >
                {
                  page.content.filter(
                    (element) => element.slug.current === "features-title"
                  )[0].value
                }
              </h1>
            </motion.div>

            <div
              className={`w-full grid grid-cols-1 space-y-3 pt-4 lg:grid-cols-3 lg:space-y-0 lg:gap-4 lg:auto-rows-fr`}
            >
              <>
                {modules?.list.map((element: any, index: number) => {
                  return (
                    <Disclosure
                      key={index}
                      label={element.title}
                      className={`flex-1 flex flex-col justify-between`}
                      color={"bg-gray-100"}
                      icon={
                        element.image && (
                          <Image
                            alt={`${element.title} logo`}
                            src={urlFor(element.image).url()}
                            layout={`fill`}
                            objectFit={`contain`}
                          />
                        )
                      }
                    >
                      {/* Card content */}
                      <div
                        className={`pb-10 pt-2.5 px-4 prose-xl 2xl:prose-2xl`}
                      >
                        <BlockContent
                          blocks={element.content}
                          serializers={serializers}
                        />
                      </div>

                      {/* Bottom bar */}

                      <div
                        className={`bg-${element.color} relative w-full flex items-center justify-end py-3.5 px-4`}
                      >
                        {/* Background svg pattern */}
                        <div className={`absolute inset-0 flex`}>
                          <div className={`relative flex-1`}>
                            <Image
                              alt={`Package background pattern`}
                              src={`/assets/brand/package-pattern.svg`}
                              layout={`fill`}
                              objectFit={`cover`}
                              objectPosition={"left"}
                              className={`z-0`}
                            />
                          </div>
                        </div>

                        {element.slug.current === "periodic-report" ? (
                          <Button
                            aria-label={t("buttons.included")}
                            icon={<CheckCircle weight={`fill`} />}
                            label={t("buttons.included")}
                            tint={`dark`}
                            className={`z-10`}
                          />
                        ) : (
                          <Button
                            aria-label={t("buttons.buy")}
                            icon={<CreditCard weight={"fill"} />}
                            label={t("buttons.buy")}
                            tint={`dark`}
                            className={`z-10`}
                            href={`/plans`}
                          />
                        )}
                      </div>
                    </Disclosure>
                  );
                })}
              </>
            </div>
          </motion.div>
        </section>
      </motion.div>
    </>
  );
}

Home.getLayout = (page, props) => {
  return (
    <General headerProps={props?.header} footerProps={props?.footer}>
      {page}
    </General>
  );
};

export default Home;
